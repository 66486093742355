.App {
  height: 100vh;
}

.head-col {
  font-weight: bold;
  color: brown;
}

.val-col {
  font-weight: 500;
}

.tooltip {
  pointer-events: none;
}

.legend_values {
  font-weight: 600;
  font-size: 13px;
  line-height: initial;
}